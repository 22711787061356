import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { isBrowser, setUser } from '@firsttable/functions';
import { gql, useQuery } from '@apollo/client';
import AuthContext from '../context/AuthContext';
import LoadingLayout from '../layouts/LoadingLayout';

const to = '/auth/sign-in/';

const VALID_TOKEN = gql`
  query userHasValidToken {
    validateToken {
      valid
      status
      code
    }
  }
`;

const PrivateRoute = ({ component: Component, pathName, ...rest }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { data, loading } = useQuery(VALID_TOKEN, {
    fetchPolicy: 'no-cache',
    ssr: false,
  });

  const location = useLocation();
  if (!isBrowser) {
    return null;
  }
  if (loading) {
    return <LoadingLayout showFooter showMenu />;
  }
  // checks if user is actually logged in according to backend
  const status = data?.validateToken?.status;
  const { pathname } = location;
  if (
    (!isLoggedIn() && pathname !== to) || // checks if user is logged in according to site and the url doesn't match current
    status === 'DEAD'
  ) {
    setUser(null);
    navigate(`${to}?backUrl=${encodeURIComponent(pathname)}`, {
      replace: true,
    });
    return null;
  }
  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  currentUser: PropTypes.object,
  pathName: PropTypes.string,
};

PrivateRoute.defaultProps = {
  currentUser: {},
};

export default PrivateRoute;
