import React from 'react';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA';
import Text from '@firsttable/web-components/atoms/Text';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import { Col, Row } from '@firsttable/web-components/atoms';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import Button from '@firsttable/web-components/atoms/Button';
import { isFirstCharacterAVowel } from '@firsttable/functions';
import { Router } from '@reach/router';
import LoadingLayout from '../../layouts/LoadingLayout';
import Footer from '../../components/organisms/Footer';
import MainMenu from '../../components/organisms/Navigation/Menu';
import ThemeLayout from '../../layouts/layout';
import PrivateRoute from '../../components/PrivateRoute';
import MissingRoute from '../../layouts/MissingRoute';
import NavButton from '../../components/atoms/NavButton';

const GET_AUTH_COMPLETE = gql`
  query getAuthComplete{
    user: Member(siteId: ${parseInt(process.env.GATSBY_SITE_ID, 10)}) {
      promoCodes {
        edges {
          node {
            id
            expires
            freeCreditNice
            freeCreditValidForDays
          }
        }
      }
      city {
        menuTitle
        id
        slug
      }
    }
  }
`;

const SignUpContent = ({ appliedCode, city }) => (
  <>
    <Text>
      Thank you for registering!
    </Text>
    {appliedCode && (
      <Text>
        {appliedCode.freeCreditNice}
        {' '}
        has been applied to your account and can be used
        towards your first booking fee.
        Get booking!
        {' '}
        {appliedCode.expires && (`Your credit will expire on ${appliedCode.expires}`)}
      </Text>
    )}
    {city && (
      <Button NavComponent={NavButton} to={city.slug} type="button" kind="cta">
        Book
        {' '}
        {isFirstCharacterAVowel(city.menuTitle) ? 'an' : 'a'}
        {' '}
        {city.menuTitle}
        {' '}
        restaurant
      </Button>
    )}
  </>
);

SignUpContent.propTypes = {
  appliedCode: PropTypes.shape(),
  city: PropTypes.shape(),
};
SignUpContent.defaultProps = {};

const SignUpComplete = () => {
  const { data, loading, error } = useQuery(GET_AUTH_COMPLETE, {
    fetchPolicy: 'network-only',
  });
  if (loading || error) {
    return (
      <LoadingLayout showFooter showMenu />
    );
  }
  const {
    user: {
      promoCodes,
      city,
    },
  } = data;

  const appliedCode = promoCodes.edges.length ? promoCodes.edges[0].node : null;

  return (
    <ThemeLayout footer={<Footer />}>
      <MainMenu />
      <ContainerContent>
        <RowHead title="Sign Up Complete" />
        <Row gap={164}>
          <Col width={[1, 1 / 2]}>
            <SignUpContent appliedCode={appliedCode} city={city} />
          </Col>
          <Col width={[1, 1 / 2]} line>
            <ContactCTA NavButtonComponent={NavButton} />
          </Col>
        </Row>
      </ContainerContent>
    </ThemeLayout>
  );
};

SignUpComplete.propTypes = {};
SignUpComplete.defaultProps = {};

const AuthComplete = () => (
  <Router>
    <PrivateRoute path="/auth/complete" component={SignUpComplete} />
    <MissingRoute default />
  </Router>
);

export default AuthComplete;
